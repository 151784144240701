import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { SnackbarProvider } from "./components/Snackbar";
import Verses from "./pages/Verses";
import { theme } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import AdminVersesForm from "./pages/Admin";
import AdminSectionsForm from "./pages/Admin/sections";
import Dashboard from "./pages/Dashboard";
import React from "react";
import { NavBar } from "./components/Navbar";
import { MobileDrawer } from "./pages/Home/MobileDrawer";
import Home from "./pages/Home";
import Bible from "./pages/Bible";
import { Movies } from "./pages/Movies";
import { Books } from "./pages/Books";
import { Prayer } from "./pages/Prayer";
import { AboutUs } from "./pages/AboutUs";

function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <BrowserRouter>
          <NavBar onDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
          <MobileDrawer
            onDrawerToggle={handleDrawerToggle}
            mobileOpen={mobileOpen}
          />
          <main>
            <Routes>
              {/* <Route path="/" element={<Home />} /> */}
              <Route path="/home" element={<Home />} />
              <Route path="/" element={<Bible />} />
              <Route path="/bible" element={<Bible />} />
              <Route path="/sections/:id" element={<Verses />} />
              <Route path="/admin" element={<AdminVersesForm type="admin" />} />
              <Route path="/admin/section" element={<AdminSectionsForm />} />
              <Route
                path="/wallp"
                element={<AdminVersesForm type="wallpaper" />}
              />
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route
                path="/admin/verse/:id"
                element={<AdminVersesForm type="admin" />}
              />
              <Route path="/movies" element={<Movies />} />
              <Route path="/books" element={<Books />} />
              <Route path="/prayer" element={<Prayer />} />
              <Route path="/about" element={<AboutUs />} />
            </Routes>
          </main>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
