import { Close as CloseIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import * as React from "react";
import { PAGES } from "./constants";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

// Add these styles near the top of your component
const styles = {
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      boxSizing: "border-box",
      backgroundColor: "primary.main",
      color: "white",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "16px",
    borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
    "& .MuiAvatar-root": {
      marginRight: "12px",
    },
  },
  drawerItem: {
    margin: "8px 16px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  drawerItemText: {
    color: "white",
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "white",
  },
};

export const MobileDrawer = ({ onDrawerToggle, mobileOpen }) => {
  const navigate = useNavigate();
  const handleNavigateNavMenu = (link) => {
    navigate(link);
  };

  const drawer = (
    <Box>
      <Box sx={styles.drawerHeader}>
        <IconButton
          sx={styles.closeButton}
          onClick={onDrawerToggle}
          color="inherit"
        >
          <CloseIcon />
        </IconButton>
        <Avatar sx={{ width: 40, height: 40 }} src="/logo192.png" />
        <Typography variant="h6" component="div">
          നന്മ മരം
        </Typography>
      </Box>

      <List sx={{ mt: 2 }}>
        {PAGES.map((item) => (
          <ListItem
            key={item?.label}
            disablePadding
            onClick={() => handleNavigateNavMenu(item?.link)}
          >
            <ListItemButton sx={styles.drawerItem}>
              <ListItemText
                primary={item?.label}
                primaryTypographyProps={{
                  sx: styles.drawerItemText,
                  fontWeight: 500,
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      {/* <Divider sx={{ my: 2, backgroundColor: "rgba(255, 255, 255, 0.12)" }} />
      
          <Box sx={{ p: 2 }}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                color: "white",
                borderColor: "white",
                "&:hover": {
                  borderColor: "white",
                  backgroundColor: "rgba(255, 255, 255, 0.08)",
                },
              }}
            >
              Login
            </Button>
          </Box> */}
    </Box>
  );
  return (
    <>
      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={onDrawerToggle}
        sx={styles.drawer}
        PaperProps={{
          sx: {
            backgroundColor: "primary.main",
          },
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};
