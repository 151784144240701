export const COUNT_OF_IMAGES = 25;

export const BIBLE_BOOKS = [
  { label: "Genesis (ഉല്പത്തി)", value: "genesis" },
  { label: "Exodus (പുറപ്പാട്)", value: "exodus" },
  { label: "Leviticus (ലേവ്യര്‍)", value: "leviticus" },
  { label: "Numbers (സംഖ്യ)", value: "numbers" },
  { label: "Deuteronomy (നിയമാവര്‍ത്തനം)", value: "deuteronomy" },
  { label: "Joshua (ജോഷ്വാ)", value: "joshua" },
  { label: "Judges (ന്യായാധിപ‌ന്‍‍മാര്‍)", value: "judges" },
  { label: "Ruth (റൂത്ത്)", value: "ruth" },
  { label: "1 Samuel (1 സാമുവല്‍)", value: "1_samuel" },
  { label: "2 Samuel (2 സാമുവല്‍)", value: "2_samuel" },
  { label: "1 Kings (1 രാജാക്കൻമാർ)", value: "1_kings" },
  { label: "2 Kings (2 രാജാക്കൻമാർ)", value: "2_kings" },
  { label: "1 Chronicles (1 ദിനവൃത്താന്തം)", value: "1_chronicles" },
  { label: "2 Chronicles (2 ദിനവൃത്താന്തം)", value: "2_chronicles" },
  { label: "Ezra (എസ്രാ)", value: "ezra" },
  { label: "Nehemiah (നെഹമിയ)", value: "nehemiah" },
  { label: "Tobit (തോബിത്)", value: "tobit" },
  { label: "Judith (യൂദിത്)", value: "judith" },
  { label: "Esther (എസ്തേര്‍)", value: "esther" },
  { label: "1 Maccabees (1 മക്കബായർ)", value: "1_maccabees" },
  { label: "2 Maccabees (2 മക്കബായർ)", value: "2_maccabees" },
  { label: "Job (ജോബ്)", value: "job" },
  { label: "Psalms (സങ്കീർത്തനങ്ങൾ)", value: "psalms" },
  { label: "Proverbs (സുഭാഷിതങ്ങള്‍)", value: "proverbs" },
  { label: "Ecclesiastes (സഭാപ്രസംഗക‌ന്‍)", value: "ecclesiastes" },
  { label: "Song of Solomon (ഉത്തമഗീതം)", value: "song_of_solomon" },
  { label: "Wisdom (ജ്ഞാനം)", value: "wisdom" },
  { label: "Sirach (പ്രഭാഷക‌ന്‍)", value: "sirach" },
  { label: "Isaiah (ഏശയ്യാ)", value: "isaiah" },
  { label: "Jeremiah (ജെറെമിയ)", value: "jeremiah" },
  { label: "Lamentations (വിലാപങ്ങള്‍)", value: "lamentations" },
  { label: "Baruch (ബാറൂക്ക്)", value: "baruch" },
  { label: "Ezekiel (എസെക്കിയേല്‍)", value: "ezekiel" },
  { label: "Daniel (ദാനിയേല്‍)", value: "daniel" },
  { label: "Hosea (ഹോസിയാ)", value: "hosea" },
  { label: "Joel (ജോയേല്‍)", value: "joel" },
  { label: "Amos (ആമോസ്)", value: "amos" },
  { label: "Obadiah (ഒബാദിയ)", value: "obadiah" },
  { label: "Jonah (യോനാ)", value: "jonah" },
  { label: "Micah (മിക്കാ)", value: "micah" },
  { label: "Nahum (നാഹൂം)", value: "nahum" },
  { label: "Habakkuk (ഹബക്കൂക്ക്)", value: "habakkuk" },
  { label: "Zephaniah (സെഫാനിയ)", value: "zephaniah" },
  { label: "Haggai (ഹഗ്ഗായി)", value: "haggai" },
  { label: "Zechariah (സഖറിയാ)", value: "zechariah" },
  { label: "Malachi (മലാക്കി)", value: "malachi" },
  { label: "Matthew (മത്തായി)", value: "matthew" },
  { label: "Mark (മർക്കോസ്)", value: "mark" },
  { label: "Luke (ലൂക്കാ)", value: "luke" },
  { label: "John (യോഹന്നാൻ)", value: "john" },
  { label: "Acts (അപ്പ.പ്രവര്‍ത്തനങ്ങള്‍)", value: "acts" },
  { label: "Romans (റോമാ)", value: "romans" },
  { label: "1 Corinthians (1 കൊറിന്തോസ്)", value: "1_corinthians" },
  { label: "2 Corinthians (2 കൊറിന്തോസ്)", value: "2_corinthians" },
  { label: "Galatians (ഗലാത്തിയാ)", value: "galatians" },
  { label: "Ephesians (എഫേസോസ്)", value: "ephesians" },
  { label: "Philippians (ഫിലിപ്പി)", value: "philippians" },
  { label: "Colossians (കൊളോസോസ്)", value: "colossians" },
  { label: "1 Thessalonians (1 തെസലോനിക്കാ)", value: "1_thessalonians" },
  { label: "2 Thessalonians (2 തെസലോനിക്കാ)", value: "2_thessalonians" },
  { label: "1 Timothy (1 തിമോത്തേയോസ്)", value: "1_timothy" },
  { label: "2 Timothy (2 തിമോത്തേയോസ്)", value: "2_timothy" },
  { label: "Titus (തീത്തോസ്)", value: "titus" },
  { label: "Philemon (ഫിലെമോ‌ന്‍)", value: "philemon" },
  { label: "Hebrews (ഹെബ്രായര്‍)", value: "hebrews" },
  { label: "James (യാക്കോബ്)", value: "james" },
  { label: "1 Peter (1 പത്രോസ്)", value: "1_peter" },
  { label: "2 Peter (2 പത്രോസ്)", value: "2_peter" },
  { label: "1 John (1 യോഹന്നാൻ)", value: "1_john" },
  { label: "2 John (2 യോഹന്നാൻ)", value: "2_john" },
  { label: "3 John (3 യോഹന്നാൻ)", value: "3_john" },
  { label: "Jude (യൂദാസ്)", value: "jude" },
  { label: "Revelation (വെളിപാട്)", value: "revelation" },
];
