import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Grid,
} from "@mui/material";
import {
  YouTube as YouTubeIcon,
  PlayArrow as PlayArrowIcon,
} from "@mui/icons-material";

const VIDEOS = [
  {
    id: 1,
    title: "വി.കുർബ്ബാനയെ അതി തീവ്രമായി സ്നേഹിച്ച ഒരു യൂട്യൂബറുടെ ജീവിതം",
    youtubeId: "9RiFTtzwq-w",
    url: "https://www.youtube.com/watch?v=9RiFTtzwq-w",
  },
  {
    id: 2,
    title: "Personal prayer അനുഭവം ആകാൻ ജെറിൽ ന്റെ 12 Step format",
    youtubeId: "enesML0PMdw",
    url: "https://www.youtube.com/watch?v=enesML0PMdw",
  },
  {
    id: 3,
    title: "മരണം വരെ കത്തിജ്വലിക്കുക",
    youtubeId: "SoG8o8yrPWk",
    url: "https://www.youtube.com/watch?v=SoG8o8yrPWk",
  },
  {
    id: 4,
    title: "പലവിചാരങ്ങൾ കാരണം വ്യക്തിപരമായി പ്രാർത്ഥിക്കാൻ പറ്റുന്നില്ലെ",
    youtubeId: "nHNB_NGtMl8",
    url: "https://www.youtube.com/watch?v=nHNB_NGtMl8",
  },
];

export const LatestVideos: React.FC = () => {
  const [videos, setVideos] = useState(VIDEOS);

  useEffect(() => {
    const API_KEY = "YOUR_API_KEY";
    const CHANNEL_ID = "CHANNEL_ID_HERE";

    fetch(
      `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=10&type=video`
    )
      .then((response) => response.json())
      .then((data) => {
        const fetchedVideos = data.items.map((item) => ({
          id: item.id.videoId,
          title: item.snippet.title,
          youtubeId: item.id.videoId,
          url: `https://www.youtube.com/watch?v=${item.id.videoId}`,
        }));
        setVideos(fetchedVideos);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const getYouTubeThumbnail = (videoId: string) => {
    return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
  };

  return (
    <Box sx={{ py: 4 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <YouTubeIcon sx={{ fontSize: "2rem", color: "#FF0000", mr: 1 }} />
        <Typography variant="h4">Latest Videos</Typography>
      </Box>
      <Grid container spacing={3}>
        {videos.map((video) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={video.id}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <Box sx={{ position: "relative" }}>
                <CardMedia
                  component="img"
                  height="157"
                  image={getYouTubeThumbnail(video.youtubeId)}
                  alt={video.title}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                    },
                  }}
                  component="a"
                  href={video.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PlayArrowIcon />
                </IconButton>
              </Box>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {video.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
